import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { ChannelScheduleCard } from '../../ChannelScheduleCard';
import { dateService } from '../../../domain/services/dateService';
import { conf, t } from '../../../domain/services/configService';
import { SmartLink } from '../../SmartLink';
import { apiClientService } from '../../../domain/services/apiClientService';
import { facePaint } from '../../../domain/theme/utils/facePaint';
import { theme } from '../../../domain/theme';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { IconPlus } from '../../Icon/IconPlus';

const styles = {
  tvGuideContainer: css`
    display: none;

    & select::-ms-expand {
      display: none;
    }

    & ul li:first-of-type a:before {
      color: #fc0;
      position: absolute;
      right: 40px;
    }

    ${theme.mq.tablet} {
      & {
        display: inline-block;
        position: relative;
        width: 100%;
        padding: 48px 0 0 60px;
      }
    }

    ${theme.mq.small_desktop} {
      & {
        padding-left: 80px;
      }
    }
    .tv-guide__primary {
      color: #fc0;
      text-transform: uppercase;
    }
  `,
  tvGuide: css`
    margin-bottom: 40px;
    background: #000;
    position: relative;
    width: 100%;
    float: left;
    clear: both;
    position: relative;
    z-index: 3;

    ${theme.mq.tablet} {
      & {
        margin-bottom: 60px;
      }
    }

    ${theme.mq.small_desktop} {
      & {
        margin-bottom: 60px;
      }
    }

    ${theme.mq.large_desktop} {
      & {
        margin-bottom: 60px;
      }
    }

    & .tv-guide__container select option {
      background-color: #000;
    }

    ${theme.mq.tablet} {
      & {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
      }

      &:before {
        background-color: rgba(255, 255, 255, 0.1);
        content: '';
        display: block;
        height: 130px;
        position: absolute;
        top: 0;
        left: 0;
        width: 540px;
      }

      &:after {
        background-color: rgba(255, 255, 255, 0.1);
        bottom: 0;
        right: 0;
        content: '';
        display: block;
        position: absolute;
        height: 150px;
        width: 160px;
        pointer-events: none;
        touch-action: auto;
      }

      & h2 {
        ${theme.font.family('regularWeb')};
        font-weight: 400;
        font-size: 2.5rem;
        letter-spacing: 5px;
        line-height: 1.5;
        color: #fff;
        float: left;
        margin: 0;
        position: relative;
        text-transform: uppercase;
      }

      & h2:before {
        content: '';
        width: 1px;
        display: block;
        position: absolute;
        background-color: #fc0;
        height: 103px;
        left: -20px;
        top: -60px;
        width: 1px;
      }

      & ul li {
        ${theme.font.family('boldWeb')};
        font-weight: 400;
        font-size: 0.75rem;
        letter-spacing: 2.5px;
        line-height: 1.5;
        color: #fff;
        font-size: 0.875rem;
        display: inline-block;
        float: right;
        margin-top: 15px;
        text-transform: uppercase;
      }

      & ul li:last-child {
        display: none;
      }

      & ul li a {
        color: #fff;
        cursor: pointer;
        margin: 0 60px;
      }
    }

    ${theme.mq.small_desktop} {
      & ul {
        width: 93%;
      }

      & ul li a {
        margin: 0;
      }

      & ul li:first-of-type a {
        margin-left: 50px;
      }

      & ul li:last-child {
        display: inline-block;
        border-right: 1px solid #fff;
        position: relative;
      }

      & ul li:last-child a {
        margin-right: 5px;
      }

      & ul li:last-child select {
        background: 0 0;
        border: none;
        color: #fff;
        margin-right: 60px;
        text-transform: uppercase;
        appearance: none;
        width: 100%;
      }

      & ul li:last-child select:active,
      & ul li:last-child select:hover {
        cursor: pointer;
        outline: 0;
      }

      & ul li:last-child:after {
        content: '';
        position: absolute;
        bottom: 13px;
        border-top: 5px solid #fff;
        border-right: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid transparent;
        top: 5px;
        right: 40px;
      }

      & .chan-card .card__header {
        left: 58%;
        margin-top: 2px;
        width: 100%;
      }

      & .chan-card .card__header a {
        width: 100%;
      }
    }

    ${theme.mq.large_desktop} {
      & .chan-card .card__header {
        left: 55%;
      }

      & .chan-card .card__header a {
        font-size: 1.375rem;
        line-height: 1.625rem;
      }

      & .chan-card .card__header:before {
        height: 17px;
      }
    }
  `,
  tvGuideCards: css`
    & .chan-card {
      margin-bottom: 0;
    }

    & > div:nth-of-type(2),
    & > div:nth-of-type(3) {
      display: none;
    }

    ${theme.mq.tablet} {
      & {
        padding: 0 60px 60px;
        margin-top: 16px;
        white-space: nowrap;
        overflow-x: scroll;
      }

      &.no-results {
        overflow-x: hidden;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      & .card.chan-card.card--medium.card--16x9 {
        float: none;
        overflow: visible;
        display: inline-block;
        padding: 30px 0 40px;
        white-space: normal;
        width: 33%;
      }

      & .chan-card .card__header {
        left: 52%;
        top: 10px;
        transform: translateX(-55%);
        width: 200px;
      }

      & .chan-card .card__header:before {
        width: 1px;
      }

      & .chan-card .card__cont--tit,
      & .chan-card .card__schedule--link {
        display: none;
      }

      & .chan-card:after,
      & .chan-card:before {
        display: none;
      }

      & .chan-card:nth-of-type(2),
      & .chan-card:nth-of-type(3) {
        display: inline-block;
        margin-left: 60px;
      }
    }

    ${theme.mq.small_desktop} {
      & {
        white-space: nowrap;
      }

      & > div:nth-of-type(2),
      & > div:nth-of-type(3) {
        margin-left: 80px;
      }
      & .card.chan-card.card--medium.card--16x9 {
        width: 320px;
      }
    }

    ${theme.mq.large_desktop} {
      & > div:nth-of-type(2),
      & > div:nth-of-type(3) {
        margin-left: 100px;
      }
    }
  `,
  packageWrapper: css`
    max-width: 1600px;
    margin: 0 auto;
    overflow: hidden;
  `,
  contentVisibility: css`
    content-visibility: auto;
    ${facePaint({
      'contain-intrinsic-size': ['0 423px', '0 268px']
    })};
  `
};
const TvGuideInner = () => {
  if (conf.tvSettings.natgeo_tv.channel_map) {
    const [data, setData] = useState({ channel: 0, shows: [] });

    const containerRef = useRef();
    let observer;

    const updateShows = async channel => {
      const now = new Date().getTime();

      const jsonFile = `/ajax/tv_schedule_by_date/${
        conf.tvSettings.natgeo_tv.channel_map[channel].machine_name
      }/schedule_${dateService.format(now, 'Y-m-d')}.json`;
      const { response } = await apiClientService.getJson(jsonFile);
      if (response) {
        const browserStartDate = new Date();
        const browserEndDate = browserStartDate;

        browserStartDate.setHours(
          conf.primetime_start_hour,
          conf.primetime_start_minute,
          0,
          0
        );
        const countryStartTimestamp =
          dateService
            .changeTimezone(browserStartDate, conf.defaultTimeZone)
            .getTime() / 1000;

        browserEndDate.setHours(
          conf.primetime_end_hour,
          conf.primetime_end_minute,
          0,
          0
        );
        let countryEndTimestamp =
          dateService
            .changeTimezone(browserEndDate, conf.defaultTimeZone)
            .getTime() / 1000;

        // Handle primetime ranges finishing on next days, like in Spain.
        if (countryEndTimestamp < countryStartTimestamp) {
          // Add one day to countryEndDate.
          countryEndTimestamp += 60 * 60 * 24;
        }

        const shows = response.filter(value => {
          if (
            value &&
            Number(value.timestamp) >= countryStartTimestamp &&
            Number(value.timestamp) <= countryEndTimestamp
          ) {
            return true;
          }
          return false;
        });
        setData({ channel, shows });
      }
    };
    useEffect(() => {
      if ('IntersectionObserver' in window) {
        observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting || entries[0].isVisible) {
            updateShows(data.channel);
            if ('IntersectionObserver' in window && observer) {
              observer.disconnect();
            }
          }
        });

        if (containerRef.current) {
          observer.observe(containerRef.current);
        }
      } else {
        updateShows(data.channel);
      }
      return () => {
        if ('IntersectionObserver' in window && observer) {
          observer.disconnect();
        }
      };
    }, []);

    const handleSelectChange = e => {
      updateShows(e.target.value);
    };

    const createHighlight = () => {
      return {
        __html: t('TV_GUIDE__TITLE').replace(
          t('TV_GUIDE__TITLE_HIGHLIGHT'),
          `<span class="tv-guide__primary">${t(
            'TV_GUIDE__TITLE_HIGHLIGHT'
          )}</span>`
        )
      };
    };

    const currentChannelMachineName =
      conf.tvSettings.natgeo_tv.channel_map[data.channel].machine_name;

    const now = new Date().getTime();
    return (
      <div
        className="tv-guide"
        ref={containerRef}
        css={[styles.contentVisibility, styles.tvGuide]}
      >
        <div className="package-wrapper" css={styles.packageWrapper}>
          <div className="tv-guide__container" css={styles.tvGuideContainer}>
            <h2
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={createHighlight()}
            />
            <ul>
              <li>
                <SmartLink
                  to={`${t(
                    '/television-schedule'
                  )}#schedule=${currentChannelMachineName}/${dateService.format(
                    now,
                    'm/d/Y'
                  )}`}
                >
                  {t('TV_GUIDE__LINK_TEXT')} <IconPlus size={12} color="#fc0" />
                </SmartLink>
              </li>
              <li>
                <select onChange={handleSelectChange}>
                  {conf.tvSettings.natgeo_tv.channel_map.map(
                    (channel, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <option key={index} value={index}>
                          {channel.channel_name}
                        </option>
                      );
                    }
                  )}
                </select>
              </li>
            </ul>
          </div>

          <div
            className={`tv-guide__cards ${
              data.shows.length <= 0 ? 'no-results' : ''
            } ${data.channel}`}
            css={styles.tvGuideCards}
          >
            {data.shows.map((show, index) => {
              if (index >= 3) {
                return;
              }

              // eslint-disable-next-line consistent-return
              return (
                <ChannelScheduleCard
                  key={`${show.nid}_${show.timestamp}`}
                  url={show.link}
                  pageStatus={show.status}
                  header={show.title}
                  time={show.timestamp * 1000}
                  size="medium"
                  aspect="16x9"
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  return <div />;
};

export const TvGuide = ParagraphWrapper(TvGuideInner);
